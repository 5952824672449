import * as React from 'react';
import { AppLocale } from '@dreamplan/types/src/EdgeStore';
import clsx from 'clsx';
import { LinksType } from '../../types/CommonTypes';
import { AuthNavs, initialLinks } from '../Navbar/Navbar';
import BurgerMenu from './BurgerMenu';

interface Props {
  isOpen: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
  links?: LinksType;
  auth: AuthNavs;
  disableClick?: boolean;
  locales: AppLocale[];
}

const MobileNavigation = ({
  isOpen,
  setOpenMenu,
  links = initialLinks,
  auth,
  disableClick,
  locales = [],
}: Props) => {
  return (
    <nav className="fixed z-[1000]">
      <BurgerMenu
        auth={auth}
        isOpen={isOpen}
        onCloseMenu={() => setOpenMenu(false)}
        className={clsx(
          'fixed inset-0 top-0 w-screen bg-white shadow-lg transition-transform duration-300 ease-in-out',
          !isOpen ? 'translate-x-full' : 'translate-x-0',
        )}
        links={links}
        disableClick={disableClick}
        locales={locales}
      />
    </nav>
  );
};

export default MobileNavigation;

'use client';
import * as React from 'react';
import { useAnalytics } from '@dreamplan/shared-ui/client/analytics';
import { AppLocale } from '@dreamplan/types/src/EdgeStore';
import { ChevronDownIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { LinksType, PageLinkType } from '../../types/CommonTypes';
import useScrollLock from '../../utils/useScrollLock';
import { Button } from '../Button';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { LocaleLink } from '../LocaleLink';
import PageLink from '../PageLink';
import { Text } from '../Typography';
import { AuthNavs, initialLinks } from './Navbar';

interface Props {
  className: string;
  links?: LinksType;
  disableClick?: boolean;
  onCloseMenu: () => void;
  isOpen: boolean;
  auth: AuthNavs;
  locales: AppLocale[];
}

export const BurgerDropdownMenu = ({
  link,
  onCloseMenu,
  disableClick,
}: {
  link: PageLinkType;
  onCloseMenu: () => void;
  disableClick?: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div
        className="flex cursor-pointer items-center justify-between pb-2"
        onClick={toggleDropdown}
      >
        {link.subNavigation && (
          <>
            <Text className="hover:text-opacity/70 text-xl">{link.page}</Text>
            <ChevronDownIcon
              className={clsx(
                'ml-2 h-3 w-9 transition-transform duration-200',
                isOpen ? 'rotate-180' : 'rotate-0',
              )}
              strokeWidth={3}
            />
          </>
        )}
      </div>
      <div
        className={clsx(
          'overflow-hidden transition duration-300 ease-in-out',
          isOpen ? 'max-h-96' : 'max-h-0',
        )}
      >
        {isOpen && link.subNavigation && (
          <ul className="mt-2 pl-4">
            {link.subNavigation.map((subLink) => (
              <li key={subLink.navigation} className="py-2 hover:opacity-70">
                <PageLink
                  link={subLink}
                  disableClick={disableClick}
                  onClick={onCloseMenu}
                  className="text-lg"
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};
const BurgerMenu = ({
  className,
  links = initialLinks,
  disableClick,
  onCloseMenu,
  isOpen,
  auth,
  locales,
}: Props) => {
  useScrollLock(isOpen);

  const { track } = useAnalytics();

  return (
    <div className={clsx(className, 'mt-20 w-full pt-3 lg:hidden')}>
      <div className="border-surface-regular flex items-center justify-between border-b">
        {auth.user.type === 'loading' && (
          <div className="flex animate-pulse items-center gap-x-2">
            <UserCircleIcon className="cursor-pointer opacity-70" height={33} width={33} />
            <div className="h-6 w-32 bg-gray-400"></div>
          </div>
        )}
      </div>
      {links.main && (
        <nav className="flex min-h-full flex-col justify-between pb-8 pt-3">
          <ul className="mt-5 flex flex-grow list-none flex-col gap-y-2 px-4">
            {links.main.map((link) => (
              <li key={link.navigation}>
                {link.subNavigation ? (
                  <BurgerDropdownMenu
                    link={link}
                    onCloseMenu={onCloseMenu}
                    disableClick={disableClick}
                  />
                ) : (
                  <PageLink
                    link={link}
                    disableClick={disableClick}
                    onClick={onCloseMenu}
                    className="pb-2 text-xl"
                  />
                )}
                <hr className="bg-gray-100" />
              </li>
            ))}
            {auth.user.type === 'signed-in' && (
              <div className="mt-4 flex cursor-pointer items-center gap-2">
                <LanguageSwitcher
                  locales={locales}
                  onOpen={() => {
                    track('LanguageSelectorClick', {});
                  }}
                  showCurrentLanguage
                />
              </div>
            )}
          </ul>
          {auth.user.type === 'unsigned' && !disableClick && (
            <div className="flex flex-col items-center space-y-4 px-4">
              <Button asChild className="w-full py-3 font-semibold" variant="outlined">
                <a href={auth.signIn.link}>{auth.signIn.text}</a>
              </Button>
              <Button asChild className="w-full py-3 font-semibold" variant="primary">
                <a href={auth.signUp.link}>{auth.signUp.text}</a>
              </Button>
            </div>
          )}
          {auth.user.type === 'signed-in' && (
            <div className="flex flex-col items-center justify-center space-y-4 px-4">
              <Button asChild className="bg-secondary-light w-full py-3 font-semibold">
                <LocaleLink href="/intro">
                  <Text className="text-center">{auth.user.name}</Text>
                </LocaleLink>
              </Button>
              <Button asChild className="border-1 w-full py-3 font-semibold" variant="outlined">
                <a href={auth.signOut.link}>{auth.signOut.text}</a>
              </Button>
            </div>
          )}
        </nav>
      )}
    </div>
  );
};

export default BurgerMenu;

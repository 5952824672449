import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';

const separatorVariants = cva([''], {
  variants: {
    orientation: {
      horizontal: 'w-full h-px border-b',
      vertical: 'h-full w-px border-l',
    },
    variant: {
      solid: 'border-solid',
      dashed: 'border-dashed',
    },
    shade: {
      default: 'border-gray-250',
      light: 'border-gray-100',
      dark: 'border-gray-400',
    },
  },
  defaultVariants: {
    orientation: 'horizontal',
    variant: 'solid',
    shade: 'default',
  },
});

type Props = {
  className?: string;
} & VariantProps<typeof separatorVariants>;

export function Separator({ className, orientation, variant, shade }: Props) {
  return (
    <div className={clsx(separatorVariants({ orientation, variant, shade }), className)}></div>
  );
}

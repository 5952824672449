'use client';
import React, { useState, useRef } from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { useAnalytics } from '@dreamplan/shared-ui/client/analytics';
import { AppLocale } from '@dreamplan/types/src/EdgeStore';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { socialInfo } from '../../constants/info';
import { LinksType } from '../../types/CommonTypes';
import useFirstInteraction from '../../utils/useFirstInteraction';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import { Button } from '../Button';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { LocaleLink } from '../LocaleLink';
import Logo from '../Logo';
import { Text } from '../Typography';
import MainNavigation from './MainNavigation';
import MobileNavigation from './MobileNavigation';

export const initialLinks = { main: [], other: [], app: socialInfo.app };

type ButtonProps = { text: string; link: string };
export type AuthNavs = {
  signIn: ButtonProps;
  signUp: ButtonProps;
  signOut: ButtonProps;
  user:
    | {
        name: string;
        email: string;
        type: 'signed-in';
        isGuest?: boolean;
      }
    | {
        type: 'unsigned';
      }
    | {
        type: 'loading';
      };
};

export type NavBarProps = {
  links?: LinksType;
  auth: AuthNavs;
  disableClick?: boolean;
  locales: AppLocale[];
};

const Navbar = (props: NavBarProps) => {
  const { links = initialLinks, auth, disableClick = false, locales } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const initialName = () => {
    if (auth.user.type !== 'signed-in') return '';
    const fullNameInitials = auth.user.name.split(' ');
    if (fullNameInitials.length === 1) {
      const firstLetter = auth.user.name.charAt(0);
      const secondLetter = auth.user.name.charAt(auth.user.name.length - 1).toUpperCase();
      return `${firstLetter}${secondLetter}`;
    }
    const firstLetter = auth.user.name.charAt(0);
    const secondLetter = auth.user.name.charAt(auth.user.name.length - 1).toUpperCase();
    return `${firstLetter}${secondLetter}`;
  };
  const isFirstInteraction = useFirstInteraction();
  const node = useRef() as any;
  // FIXME: it not a good practice to access context provider in core component directly
  const { t } = useTranslation('navigation');
  const { track } = useAnalytics();
  useOnClickOutside(node, () => setOpenMenu(false));

  return (
    <header className="z-[1000] w-full bg-white px-4 py-5 lg:py-3 print:hidden" ref={node}>
      <div className="mx-auto max-w-screen-xl">
        <div className="flex items-center justify-between">
          <div>
            <LocaleLink href="/" aria-label={t('logo_aria_label')}>
              <Logo fill={'black'} width={50} height={50} className="lg:h-16 lg:w-16" />
            </LocaleLink>
          </div>

          {!disableClick && links.main && (
            <MainNavigation
              links={links.main}
              className="hidden flex-1 items-center justify-center gap-x-8 lg:flex lg:flex-row"
            />
          )}
          <div className="flex items-center space-x-3 lg:space-x-4">
            {auth.user.type === 'unsigned' ? (
              <div className="flex items-center">
                <LanguageSwitcher
                  onOpen={() => {
                    track('LanguageSelectorClick', {});
                  }}
                  locales={locales}
                />
              </div>
            ) : (
              <div className="invisible flex lg:visible">
                <LanguageSwitcher
                  onOpen={() => {
                    track('LanguageSelectorClick', {});
                  }}
                  locales={locales}
                />
              </div>
            )}

            <div className="flex items-center justify-end space-x-4">
              {auth.user.type === 'signed-in' && (
                <div className="flex items-center space-x-4">
                  {!auth.user.isGuest ? (
                    <div className="bg-secondary mr-1 flex items-center rounded-md lg:rounded-3xl">
                      <LocaleLink href="/intro">
                        <Text className="px-3 py-1 font-medium text-[#1A1A1A] lg:hidden">
                          {initialName()}
                        </Text>
                      </LocaleLink>
                      <Button
                        className="bg-secondary-light hover:bg-secondary hidden px-6 py-3 text-[#1A1A1A] lg:block"
                        asChild
                      >
                        <LocaleLink href="/intro">
                          <Text>{auth.user.name}</Text>
                        </LocaleLink>
                      </Button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="h-6 w-[1px] bg-gray-100 lg:hidden"></div>
                  <Button
                    asChild
                    variant="outlined"
                    className="hidden text-base font-medium lg:block"
                    size="large"
                  >
                    <a href={auth.signOut.link}>{auth.signOut.text}</a>
                  </Button>
                  <button
                    aria-label={t('mobile_menu_aria_label')}
                    onClick={() => setOpenMenu(!openMenu)}
                    className="flex items-center lg:hidden print:hidden"
                  >
                    {openMenu ? (
                      <XMarkIcon height={35} width={35} fill={'black'} className="ml-auto" />
                    ) : (
                      <Bars3Icon height={35} width={35} fill={'black'} className="ml-auto" />
                    )}
                  </button>
                </div>
              )}
            </div>
            {auth.user.type === 'unsigned' && !disableClick && (
              <>
                <Button
                  asChild
                  className="hidden border-2 px-8 py-2 font-semibold lg:block"
                  variant="outlined"
                >
                  <a href={auth.signIn.link}>{auth.signIn.text}</a>
                </Button>
                <Button
                  asChild
                  className="hidden px-8 py-2 font-semibold lg:block"
                  variant="primary"
                >
                  <a href={auth.signUp.link}>{auth.signUp.text}</a>
                </Button>
                <hr className="h-6 w-[1px] bg-gray-100 lg:hidden" />
                <button
                  aria-label={t('mobile_menu_aria_label')}
                  onClick={() => setOpenMenu(!openMenu)}
                  className="flex items-center lg:hidden print:hidden"
                >
                  {openMenu ? (
                    <XMarkIcon width={35} height={35} fill={'black'} className="ml-auto" />
                  ) : (
                    <Bars3Icon width={35} height={35} fill={'black'} className="ml-auto" />
                  )}
                </button>
              </>
            )}

            {auth.user.type === 'loading' && (
              <div className="flex w-full max-w-xs animate-pulse items-center gap-x-2">
                <div className="h-10 w-40 rounded-full bg-gray-400 px-8 py-2"></div>
                <div className="h-10 w-40 rounded-full bg-gray-400 px-8 py-2"></div>
              </div>
            )}
          </div>
          {isFirstInteraction && (
            <MobileNavigation
              links={links}
              isOpen={openMenu}
              setOpenMenu={setOpenMenu}
              auth={auth}
              disableClick={disableClick}
              locales={locales}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;

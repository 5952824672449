'use client';
import * as React from 'react';
import { useAnalytics } from '@dreamplan/shared-ui/client/analytics';
import clsx from 'clsx';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { PageLinkType } from '../../types/CommonTypes';
import { Text } from '../Typography';

interface Props {
  link: PageLinkType;
  disableClick?: boolean;
  onClick?: () => void;
  className?: string;
}

const PageLink = ({ link, disableClick, onClick, className }: Props) => {
  const { page, navigation } = link;
  const params = useParams();
  const { track } = useAnalytics();
  if (navigation === null) {
    return <div>{page}</div>;
  }
  const localePrefixed = React.useMemo(() => {
    const localePath = `/${params['locale']}`;
    if (params['locale'] === 'da') {
      return navigation;
    } else if (navigation?.startsWith(localePath)) {
      return navigation;
    } else {
      return `${localePath}${navigation}`;
    }
  }, [params['locale'], navigation]);

  return (
    <Link
      href={localePrefixed ?? 'da'}
      className={clsx(
        {
          'text-tertiary cursor-pointer py-5 text-xl hover:opacity-70 lg:text-lg': !disableClick,
        },
        {
          'text-tertiary pointer-events-none cursor-pointer py-5 text-xl hover:opacity-70 lg:text-lg':
            disableClick,
        },
      )}
      onClick={() => {
        onClick?.();
        track('MenuClick', { link: localePrefixed, page: page });
      }}
    >
      <Text className={className}>{page}</Text>
    </Link>
  );
};

export default PageLink;

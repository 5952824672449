/* eslint-disable @next/next/no-img-element */
'use client';
import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { TCultureCodes } from '@dreamplan/utils';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { usePathname, useRouter } from 'next/navigation';
import { mainPages } from '../../../constants/locales';
import { Pages } from '../../types/Locales';
import { useLocale } from '../../utils/useLocale';
import { Button } from '../Button';
import Modal from '../Modal';
import { Separator } from '../Separator';
import { Text } from '../Typography';
import { SelectDropdown } from './SelectDropdown';

type BaselineLocaleInfo = {
  locale: string;
  strictRedirect?: string;
  isSupportedCountry: boolean;
  preview: string;
};

type LocaleItem = {
  locale: string;
  flag: string;
  path: string;
  isSupportedCountry: boolean;
};

function Title({ selectedKey, type }: { selectedKey: string; type: 'language' | 'country' }) {
  const { t } = useTranslation('navigation');

  return (
    <div className="flex w-full">
      <div className="flex gap-x-2">
        <img
          src={`/assets/flags/${selectedKey}.svg`}
          alt={selectedKey + ' ' + type}
          className="h-4 w-5 rounded-[4px] object-cover"
        />
        <Text className="font-medium text-[#262139]">
          {type === 'language'
            ? `${t(selectedKey + '_language')}`
            : `${t(selectedKey + '_country')}`}
        </Text>
      </div>
    </div>
  );
}

function LanguageOptions({
  onLanguageOptionSelect,
  languages,
}: {
  onLanguageOptionSelect: (selectedLocale: TCultureCodes) => void;
  languages: LocaleItem[];
}) {
  const { t } = useTranslation('navigation');

  return (
    <div className="flex flex-col p-2.5">
      {languages.map((languageData: LocaleItem) => (
        <button
          key={languageData.locale}
          className="flex flex-col rounded-md p-2.5 hover:bg-[#F5EEE7]"
          onClick={() => onLanguageOptionSelect(languageData.locale as TCultureCodes)}
        >
          <div className="flex items-start gap-x-2.5 text-start">
            <img
              src={languageData.flag}
              alt={languageData.locale + ' language'}
              className="h-4 w-5 rounded-[4px] object-cover"
            />
            <Text className="font-medium text-[#262139]">
              {t(languageData.locale + '_language')}
            </Text>
          </div>
        </button>
      ))}
    </div>
  );
}

function CountryOptions({
  onCountryOptionSelect,
  countries,
}: {
  onCountryOptionSelect: (selectedCountry: TCultureCodes) => void;
  countries: TCultureCodes[];
}) {
  const { t } = useTranslation('navigation');

  return (
    <div className="flex flex-col p-2.5">
      {countries.map((country: TCultureCodes) => (
        <button
          key={country}
          className="flex flex-col rounded-md p-2.5 hover:bg-[#F5EEE7]"
          onClick={() => onCountryOptionSelect(country)}
        >
          <div className="flex items-start gap-x-2.5 text-start">
            <img
              src={`/assets/flags/${country}.svg`}
              alt={country + ' country icon'}
              className="h-4 w-5 rounded-[4px] object-cover"
            />
            <Text className="font-medium text-[#262139]">{t(country + '_country')}</Text>
          </div>
          <div>
            <Text className="text-xs text-gray-700">{t(country + '_country_taxation_label')}</Text>
          </div>
        </button>
      ))}
    </div>
  );
}

const determinePath = (currentLocale: string, targetLocale: string, path: string) => {
  const normalizedPath = path.replace(new RegExp(`/${currentLocale}(?=/)`), '');

  if (normalizedPath === '/') return `/${targetLocale === 'da' ? '' : targetLocale}`;
  const index = mainPages[currentLocale as keyof Pages]
    .map((page) => page.navigation)
    .indexOf(normalizedPath);

  // If the current page is not in the main navigation, just add the locale to the path
  if (index === -1) return `/${targetLocale}${normalizedPath}`;
  const targetLinks = mainPages[targetLocale as keyof Pages];
  const link = targetLinks[index]?.navigation;

  return link ? `/${targetLocale}${link}` : `/${targetLocale}`;
};

const toLocaleItems = (
  locales: BaselineLocaleInfo[],
  pathname: string,
  currentLocale: string,
): LocaleItem[] => {
  return locales.map(({ locale, isSupportedCountry, strictRedirect, preview }) => ({
    locale,
    flag: `/assets/flags/${locale}.svg`,
    path: determinePath(currentLocale ?? 'da', locale, pathname),
    isSupportedCountry: isSupportedCountry ?? false,
    strictRedirect,
    preview,
  }));
};

export default function LanguageSwitcher({
  locales,
  onOpen,
  showCurrentLanguage = false,
}: {
  locales: BaselineLocaleInfo[];
  onOpen: () => void;
  showCurrentLanguage?: boolean;
}) {
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const [hideVisibleOptions, setHideVisibleOptions] = React.useState(true);

  const pathname = usePathname();
  const { t } = useTranslation('navigation');
  const locale = useLocale();
  const router = useRouter();
  const supportedLanguages = toLocaleItems(locales, pathname, locale);

  const [selectedLanguageKey, setSelectedLanguageKey] = React.useState(locale);
  const [selectedCountryKey, setSelectedCountryKey] = React.useState('da');

  const supportedCountries: TCultureCodes[] = supportedLanguages
    .filter((lang: LocaleItem) => lang.isSupportedCountry)
    .map((lang) => lang.locale as TCultureCodes);

  const handleSave = () => {
    const strictRedirectsMap: { [key: string]: string } = {};

    let newPath = '';

    locales.map(({ strictRedirect, locale }) => {
      if (strictRedirect) {
        strictRedirectsMap[locale] = strictRedirect;
      }
    });

    const isPathnameStrictRedirect = Object.values(strictRedirectsMap).includes(pathname);

    if (strictRedirectsMap[selectedCountryKey]) {
      newPath = strictRedirectsMap[selectedCountryKey];
    } else if (isPathnameStrictRedirect) {
      newPath = '/' + selectedLanguageKey;
    } else if (pathname === '/' + locale) {
      newPath = selectedLanguageKey;
    } else {
      newPath = supportedLanguages.find((lang) => lang.locale === selectedLanguageKey)?.path ?? '';
    }
    if (newPath !== pathname) {
      router.push(newPath);
    }
    setIsPickerOpen(false);
  };

  return (
    <>
      <button
        onClick={() => {
          setIsPickerOpen(true);
          onOpen();
        }}
        className="flex items-center space-x-2"
      >
        <GlobeAltIcon width={24} height={24} strokeWidth={1.4} />
        {showCurrentLanguage && (
          <span className="text-tertiary pl-1 text-base">
            {locales.find((item) => item.locale === locale)?.preview ?? 'Dansk'}
          </span>
        )}
        <ChevronDownIcon
          className="flex items-center"
          stroke="currentColor"
          height={30}
          width={10}
          strokeWidth={3}
        />
      </button>

      {isPickerOpen && (
        <Modal
          onClose={() => setIsPickerOpen(false)}
          className="mx-5 flex h-fit w-full max-w-screen-sm flex-col overflow-visible rounded-2xl"
        >
          <div
            className="pt-6 lg:p-5"
            onClick={() => {
              setHideVisibleOptions(false);
              setTimeout(() => {
                setHideVisibleOptions(true);
              }, 0);
            }}
          >
            <Text variant={'h2'} className="fonte-alternative pb-10 pt-5 text-[#262139] lg:pt-0">
              {t('language_switch_headline')}
            </Text>
            <div className="flex flex-col justify-center gap-x-6 lg:flex-row lg:justify-between">
              <div className="w-full">
                <Text variant={'large'} className="pb-3 text-gray-700">
                  {t('language_label')}
                </Text>
                <SelectDropdown
                  title={<Title selectedKey={selectedLanguageKey} type="language" />}
                  options={
                    <LanguageOptions
                      languages={supportedLanguages}
                      onLanguageOptionSelect={(selectedLanguageKey: TCultureCodes) =>
                        setSelectedLanguageKey(selectedLanguageKey)
                      }
                    />
                  }
                  onChange={(selected: string) => setSelectedLanguageKey(selected as TCultureCodes)}
                  isOptionsClosed={hideVisibleOptions}
                />
              </div>
              <div className="w-full pt-5 lg:pt-0">
                <Text variant={'large'} className="pb-3 text-gray-700">
                  {t('country_label')}
                </Text>
                <SelectDropdown
                  title={<Title selectedKey={selectedCountryKey} type="country" />}
                  options={
                    <CountryOptions
                      onCountryOptionSelect={(selectedCountryKey: TCultureCodes) =>
                        setSelectedCountryKey(selectedCountryKey)
                      }
                      countries={supportedCountries}
                    />
                  }
                  onChange={(selected: string) => setSelectedCountryKey(selected as TCultureCodes)}
                  isOptionsClosed={hideVisibleOptions}
                />
              </div>
            </div>
            <Separator className="mb-6 border-gray-300 pt-6 lg:mb-10 lg:pt-10" />
            <Button
              key={selectedLanguageKey}
              onClick={handleSave}
              size={'full'}
              className="font-medium"
            >
              {t('save_language_settings')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

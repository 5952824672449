import * as React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type SelectDropdownProps = {
  title: React.ReactNode;
  options: React.ReactNode;
  onChange?: (selected: string) => void;
  disabled?: boolean;
  className?: string;
  isOptionsClosed: boolean;
};

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  title,
  options,
  onChange,
  disabled,
  className,
  isOptionsClosed,
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    if (!isOptionsClosed) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  React.useEffect(() => {
    if (onChange) {
      setIsMenuOpen(false);
    }
  }, [onChange]);

  return (
    <div
      className={clsx(className, 'flex w-full flex-col')}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        disabled={disabled}
        onClick={() => {
          toggleMenu();
        }}
        className="w-full"
      >
        <div
          className={clsx(
            isMenuOpen ? 'border-[#262139]' : 'border-gray-300',
            'flex justify-between gap-x-2 rounded-lg border  p-3',
            disabled ? 'pointer-events-none cursor-default border-none bg-gray-100' : '',
          )}
        >
          {title}
          {!disabled && (
            <ChevronDownIcon
              className={clsx(
                'h-4 w-4 shrink-0 text-[#262139] transition-transform duration-300 group-hover:text-gray-500 print:hidden',
                isMenuOpen ? 'rotate-180' : '',
              )}
              aria-hidden="true"
            />
          )}
        </div>
      </button>
      <div className="relative">
        <div
          className={clsx(
            'relative z-10 mt-1 w-full rounded-xl border border-gray-300 bg-white lg:absolute',
            isMenuOpen ? 'animate-fade-in block duration-100' : 'hidden',
          )}
        >
          {options}
        </div>
      </div>
    </div>
  );
};
